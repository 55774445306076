<template>
  <div>
    <!-- <Navigation :show-back="true" />
    <v-main>
      <v-container> -->
        <v-banner>
          <v-icon slot="icon" color="info" size="36">
            mdi-information
          </v-icon>
          If the document is not shown below, open document externally.

          <template v-slot:actions>
            <v-btn color="primary" text :href="src" target="_blank">
              View Document Externally
            </v-btn>
          </template>
        </v-banner>
        <embed
          :src="src"
          type="application/pdf"
          width="100%"
          style="background-color:#ffffff;margin-top:15px;height:80vh;"
        />
      <!-- </v-container>
    </v-main> -->
  </div>
</template>
<script>
// import Navigation from "@/components/Navigation";

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  // components: {
  //   Navigation,
  // },
};
</script>
